import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

interface DisabledEcommerceInfoBoxProps {
    $style: any;
    info: any;
}

const DisabledEcommerceInfoBox = ({ $style, info }: DisabledEcommerceInfoBoxProps) => {
    const [css] = useStyletron();

    if (!info) return null;

    return (
        <div
            className={css({
                backgroundColor: 'var(--color-bg-disabled-ecommerce-info)',
                padding: '12px',
                color: 'var(--color-text-disabled-ecommerce-info)',
                border: '1px solid var(--color-border-disabled-ecommerce-info)',
                ...$style,
            })}
        >
            <RichText data={info} />
        </div>
    );
};

export default DisabledEcommerceInfoBox;
