import fm from 'format-message';
import PropTypes from 'prop-types';
import useCheckoutUrlQuery from 'queries/useCheckoutUrlQuery';
import useSiteQuery from 'queries/useSiteQuery';
import DisabledEcommerceInfo from '@activebrands/core-web/components/DisabledEcommerceInfo';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { ecommerceEnabled } from '@activebrands/core-web/utils/constants';
import ThemeButton from 'components/buttons/ThemeButton';
import Paragraph from 'components/text/Paragraph';

const SummaryWrapper = styled('div', {
    width: '100%',
    borderTop: '1px solid var(--color-border-basket)',
    backgroundColor: 'var(--color-bg-header)',
});

const Subtotal = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
});

const Summary = ({ totals, overlay }) => {
    const [css] = useStyletron();
    const { disabledEcommerceInfoParagraph } = useSiteQuery();
    const { checkoutPage } = useCheckoutUrlQuery();

    const closeOverlay = () => {
        overlay.close('basket');
    };

    return (
        <SummaryWrapper>
            {totals.map(item => (
                <Subtotal key={item.label}>
                    <Paragraph fontKeys="Primary/14_100_-3">{item.label}:</Paragraph>
                    <Paragraph fontKeys="Primary/14_100_-3">{item.total}</Paragraph>
                </Subtotal>
            ))}
            <div className={css({ display: 'flex', gap: '8px', padding: '0px 16px 16px 16px' })} onClick={closeOverlay}>
                <ThemeButton
                    $style={{
                        width: ecommerceEnabled ? '50%' : '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '40px',
                        alignItems: 'center',
                    }}
                    fontKeys="Primary/16_100_-05"
                    theme="outlined"
                >
                    {fm('Continue shopping')}
                </ThemeButton>
                {ecommerceEnabled && (
                    <ThemeButton
                        $style={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            height: '40px',
                            alignItems: 'center',
                        }}
                        as={Link}
                        fontKeys="Primary/16_100_-05"
                        theme="inverted"
                        to={checkoutPage}
                    >
                        {fm('Go to checkout')}
                    </ThemeButton>
                )}
            </div>
            {!ecommerceEnabled && (
                <div className={css({ padding: '0 16px 16px' })}>
                    <DisabledEcommerceInfo info={disabledEcommerceInfoParagraph} />
                </div>
            )}
        </SummaryWrapper>
    );
};

Summary.propTypes = {
    overlay: PropTypes.object.isRequired,
    totals: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, total: PropTypes.string })).isRequired,
};

export default Summary;
